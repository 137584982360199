import axios from "axios"

const BackendAPI = "https://payroll.nimbleio.ai/api";

export const FetchEmailReports = async () => {
    try {

        const response = await axios.get(`${BackendAPI}/get_files`)
        return response

    } catch (error) {
        console.log(error)
    }
}

export const fetchReportIntities = async (extraction_id) => {
    try {

        const responce = await axios.get(`${BackendAPI}/get_extraction_res`, { params: { extraction_id } })
        return responce

    } catch (error) {
        console.log(error)
    }
}
