import React, { useState, useEffect } from "react";
import {
  Stack,
  Box,
  LinearProgress,
  Typography,
  Tabs,
  Tab,
} from "@mui/material";
import ReactSplit, { SplitDirection } from "@devbookhq/splitter";
import FilePanel from "./FilePanel/FilePanel";
import FormPanel from "./FormPanel/FormPanel";
import { fetchReportIntities } from "../../API/API";
import "../ExcelTable.css";

import { OutTable, ExcelRenderer } from "react-excel-renderer";
import { read, utils } from "xlsx";

const ReviewPage = ({ report }) => {
  let s3_uri = `https://nimbleocrbills.s3.us-east-1.amazonaws.com/${report["s3_key"]}`;

  const [payRollReport, setPayRollReport] = useState();
  const ext = report["s3_key"].split(".").pop().toLowerCase();
  const spreadSheet = ["xls", "csv", "xlsx"].includes(ext);

  const [currentSheet, setCurrentSheet] = useState(0);
  const [sheets, setSheets] = useState({});

  const fileHandler = async () => {
    try {
      const response = await fetch(s3_uri);
      const fileObj = await response.blob();
      const arrayBuffer = await fileObj.arrayBuffer();

      const workbook = read(arrayBuffer, { type: "array" });
      const sheetNames = workbook.SheetNames;

      const parsedSheets = {};
      sheetNames.forEach((sheetName) => {
        const sheet = workbook.Sheets[sheetName];
        parsedSheets[sheetName] = utils.sheet_to_json(sheet, { header: 1 });
      });

      setSheets(parsedSheets);
    } catch (err) {
      console.error(err);
      setSheets({});
    }
  };

  useEffect(() => {
    if (spreadSheet) {
      fileHandler();
    }
  }, []);

  useEffect(() => {
    fetchReport();
  }, []);

  const fetchReport = async () => {
    let res = await fetchReportIntities(report["extraction_id"]);
    let data = res.data;
    if (data) {
      setPayRollReport(data["extraction_res"]);
    }
  };

  const renderTable = (sheetData) => {
    if (!sheetData || sheetData.length === 0) {
      // return <Typography>No data available for this sheet.</Typography>;
      return <LinearProgress />;
    }

    const maxColumns = Math.max(...sheetData.map((row) => row.length));

    const formattedData = sheetData.map((row, rowIndex) => {
      const filledRow = [...row];
      while (filledRow.length < maxColumns) {
        filledRow.push("");
      }
      return [rowIndex + 1, ...filledRow];
    });

    return (
      <div className="table-container">
        <table className="table">
          <thead>
            <tr>
              <th className="sticky-left">#</th>
              {formattedData[0].slice(1).map((col, index) => (
                <th key={index}>{col || " "}</th>
              ))}
            </tr>
          </thead>
          <tbody>
            {formattedData.slice(1).map((row, rowIndex) => (
              <tr key={rowIndex}>
                <td className="sticky-left">{row[0]}</td>
                {row.slice(1).map((cell, colIndex) => (
                  <td key={colIndex}>{cell || " "}</td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    );
  };

  return payRollReport ? (
    <Stack direction="row" spacing={0.5} height={"100%"}>
      {ext === "pdf" ? (
        <iframe
          src={s3_uri}
          width="55%"
          height="100%"
          // frameBorder="0"
          style={{
            border: "1px solid #000",
            borderRadius: "5px",
            boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
          }}
        ></iframe>
      ) : (
        <Box
          width={"55%"}
          sx={{ overflow: "auto" }}
          border={2}
          borderColor={"divider"}
        >
          {/* <Tabs
            value={currentSheet}
            onChange={(e, newValue) => setCurrentSheet(newValue)}
            variant="scrollable"
            scrollButtons="auto"
          >
            {Object.keys(sheets).map((sheetName, index) => (
              <Tab key={index} label={sheetName} />
            ))}
          </Tabs>
          <Box p={2}>
            {renderTable(sheets[Object.keys(sheets)[currentSheet]])}
          </Box> */}
          <iframe
            src={`https://view.officeapps.live.com/op/view.aspx?src=${encodeURIComponent(
              s3_uri
            )}&wdHideGridlines=true&wdHideHeadersFooters=true`}
            className="preview-frame"
            title="Document Preview"
            aria-label="Document viewer"
            role="document"
            style={{height:"100%", width:"100%", border:0}}
          />
        </Box>
      )}

      <Box width={"45%"} sx={{ overflow: "auto" }}>
        <FormPanel data={payRollReport} />
      </Box>
    </Stack>
  ) : (
    <LinearProgress />
  );
};

export default ReviewPage;
