import { AppBar, Toolbar as MuiToolbar, Typography } from "@mui/material"

const Header = () => {

    return (
        <AppBar position="static" sx={{ backgroundColor: 'white', boxShadow: "0px 5px 15px -3px rgba(0, 0, 0, 0.05)" }}>
            <MuiToolbar sx={{ alignItems: 'center', gap: 3 }}>
                <Typography
                    fontWeight={'bold'}
                    fontSize={'1.4rem'}
                    color={"#151D48"}>
                    Payroll
                </Typography>

            </MuiToolbar >
        </AppBar>


    )


}

export default Header