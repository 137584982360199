import React, { useEffect, useState } from "react";
import {
    AppBar,
    Box,
    Button,
    Chip,
    Dialog,
    DialogContent,
    IconButton,
    lighten,
    Stack,
    Toolbar,
    Typography,
} from "@mui/material";
import {
    ArrowDownward,
    ArrowUpward,
    Clear,
    Download,
    EmailOutlined,
    Padding,
} from "@mui/icons-material";
import { CloudUpload } from "@mui/icons-material";
import { statusMap } from '../../Home/Home'
import ReviewPage from "../../Components/Reviewpage/ReviewPage";

import moment from "moment";
import { Table } from "rsuite";
const { Column, HeaderCell, Cell } = Table;

export const EmailComponent = ({ data }) => {

    const FROM_MAIL_COLOR = "#67E0BC";
    const ACTION_BUTTON_COLOR = "#003dfe";

    const [expandedRowKeys, setExpandedRowKeys] = useState([]);
    const [reportopen, setreportOpen] = useState(false);
    const [reportData, setReportData] = useState(null);

    const handleClickOpenDialog = (data) => {
        setReportData(data)
        setreportOpen(true);
    };

    const handleCloseDialog = () => {
        setreportOpen(false);
    };

    async function downloadFile(s3_key) {
        let s3_uri = `https://nimbleocrbills.s3.us-east-1.amazonaws.com/${s3_key}`;
        let fileName = s3_uri.split("/").pop();
    
        try {
          const response = await fetch(s3_uri);
          const blob = await response.blob();
          const link = document.createElement("a");
          const urlObject = URL.createObjectURL(blob);
    
          link.href = urlObject;
          link.setAttribute("download", fileName);
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
    
          URL.revokeObjectURL(urlObject);
        } catch (error) {
          console.error("Download failed:", error);
        }
      }


    return (
        <>
            <Table
                bordered
                cellBordered
                virtualized
                fillHeight
                data={prepareData(data)}
                isTree
                rowKey="_id"
                shouldUpdateScroll={false}
                rowHeight={(rowData) => (rowData?.treeHeader ? 115 : 46)}
                translate3d
                expandedRowKeys={expandedRowKeys}
                renderTreeToggle={() => false}
            >
                <Column
                    flexGrow={1}
                    verticalAlign="middle"
                    colSpan={4}
                >
                    <HeaderCell>Filename</HeaderCell>
                    <Cell
                        children={(rowData, rowIndex) => {
                            if (rowData.treeHeader) {
                                let reportStatus = statusCounts(rowData["children"]);
                                let expanded = expandedRowKeys.includes(rowData["_id"]);
                                return (
                                    <Stack
                                        direction={"row"}
                                        width={"100%"}
                                        alignItems={"center"}
                                        justifyContent={"space-between"}
                                    >
                                        <Stack direction={"row"} gap={4} alignItems={"center"}>
                                            <Box
                                                bgcolor={"#effdd9"}
                                                borderRadius={"50%"}
                                                height={38}
                                                width={38}
                                                display="flex"
                                                alignItems="center"
                                                justifyContent="center"
                                            >
                                                {rowData["type"] === "upload" ?
                                                    <CloudUpload   sx={{ color: FROM_MAIL_COLOR, fontSize: 28 }}/>
                                                    :
                                                    <EmailOutlined
                                                        sx={{ color: FROM_MAIL_COLOR, fontSize: 28 }}
                                                    />
                                                }
                                            </Box>

                                            <Stack gap={0.5}>
                                                <InfoRow
                                                    label="From"
                                                    value={rowData["from"]}
                                                    valueProps={{
                                                        fontSize: 18,
                                                        color: "#67E0BC",
                                                    }}
                                                />

                                                <InfoRow
                                                    label="Subject"
                                                    value={rowData["subject"]}
                                                    valueProps={{ fontSize: 14 }}
                                                />

                                                <InfoRow
                                                    label="Received on"
                                                    value={moment(rowData["receivedDateTime"]).format(
                                                        "MMMM DD[,] YYYY hh:mmA"
                                                    )}
                                                    valueProps={{ fontSize: 14 }}
                                                />
                                            </Stack>
                                        </Stack>

                                        {!expanded && (
                                            <Stack direction={"row"} gap={2}>
                                                {Object.entries(reportStatus).map(
                                                    ([status, stats], index) => (
                                                        <Chip
                                                            key={index}
                                                            size="small"
                                                            label={`${status} (${stats["count"]})`}
                                                            sx={{
                                                                bgcolor: lighten(stats["color"], 0.8),
                                                                color: stats["color"],
                                                            }}
                                                        />
                                                    )
                                                )}
                                            </Stack>
                                        )}
                                    </Stack>
                                );
                            }
                            else {
                                return rowData["source_filename"];
                            }
                        }}
                    />
                </Column>

                <Column flexGrow={1}>
                    <HeaderCell>Status</HeaderCell>
                    <Cell
                        children={(rowData) => {
                            if (rowData.treeHeader) {
                                return null;
                            }
                            let reportStat = statusMap[rowData["file_status"]];

                            let status = reportStat["name"];
                            let color = reportStat["color"];

                            return (
                                <Chip
                                    label={status}
                                    size="small"
                                    sx={{
                                        bgcolor: lighten(color, 0.8),
                                        color: color,
                                    }}
                                />
                            );
                        }}
                    />
                </Column>

                <Column>
                    <HeaderCell>Action</HeaderCell>
                    <Cell
                        children={(rowData) => {
                            if (rowData.treeHeader) {
                                return null;
                            }
                            return (
                                <Button
                                    disableRipple
                                    size="small"
                                    disabled= {rowData["extraction_id"] === null} 
                                    sx={{
                                        borderRadius: 1,
                                        bgcolor: lighten(ACTION_BUTTON_COLOR, 0.9),
                                        color: ACTION_BUTTON_COLOR,
                                        ":hover": {
                                            bgcolor: lighten(ACTION_BUTTON_COLOR, 0.9),
                                        },
                                    }}
                                    onClick={() => handleClickOpenDialog(rowData)}
                                >
                                    View
                                </Button>
                            );
                        }}
                    />
                </Column>

                <Column width={130} fixed={"right"}>
                    <HeaderCell>Download</HeaderCell>
                    <Cell
                        verticalAlign="middle"
                        align="center"
                        style={{ padding: 0 }}
                        children={(rowData) => {
                            let rowKey = rowData["_id"];
                            let expanded = expandedRowKeys.includes(rowKey);
                            return rowData.treeHeader ? (
                                <Button
                                    fullWidth
                                    disableRipple
                                    sx={{
                                        borderRadius: 0,
                                        height: "100%",
                                        bgcolor: "#e0f9f1",
                                        ":hover": {
                                            bgcolor: "#e0f9f1",
                                        },
                                        color: "black",
                                    }}
                                    endIcon={expanded ? <ArrowUpward /> : <ArrowDownward />}
                                    onClick={() =>
                                        setExpandedRowKeys((prevKeys) =>
                                            expanded
                                                ? prevKeys.filter((key) => key !== rowKey)
                                                : [...prevKeys, rowKey]
                                        )
                                    }
                                >
                                    {`Files (${getFileCount(rowData.children)})`}
                                </Button>
                            ) : (
                                <IconButton
                                    // disabled={rowData["extraction_id"] === null}
                                    disableRipple
                                    size="small"
                                    sx={{
                                        bgcolor: "#4cd3aa",
                                        borderRadius: 1,
                                        height: 20,
                                        width: 20,
                                        ":hover": {
                                            bgcolor: "#4cd3aa",
                                        },
                                    }}
                                    onClick={() =>
                                        downloadFile(rowData["s3_key"])
                                    }
                                >
                                    <Download sx={{ fontSize: 18, color: "white" }} />
                                </IconButton>
                            );
                        }}
                    />
                </Column>

            </Table>


            <Dialog open={reportopen} fullScreen>
                <AppBar position="static">
                    <Toolbar variant="dense">
                        <Stack direction={"row"} gap={1} alignItems={"center"} flexGrow={1}>
                            <Typography variant="h5">Review</Typography>
                        </Stack>

                        <IconButton color="inherit" onClick={handleCloseDialog}>
                            <Clear />
                        </IconButton>
                    </Toolbar>
                </AppBar>
                <DialogContent sx={{ p: 1 ,overflow:"hidden"}} >
                    <ReviewPage report={reportData} />;
                </DialogContent>
            </Dialog>
        </>
    )
}

function InfoRow({ value, label, valueProps }) {
    return (
        <Stack
            direction={"row"}
            gap={1}
            alignItems={"center"}
            fontFamily={"inherit"}
        >
            <Typography width={96} fontSize={12}>
                {label}
            </Typography>
            <Typography fontWeight={500} {...valueProps}>
                {value}
            </Typography>
        </Stack>
    );
}

function statusCounts(report) {
    return report.reduce((acc, rprt) => {
        const status = rprt.file_status
        const statusInfo = statusMap[status] || {
            name: "UNKNOWN",
            color: "#808080",
        };

        if (!acc[statusInfo.name]) {
            acc[statusInfo.name] = { count: 0, color: statusInfo.color };
        }

        acc[statusInfo.name].count += 1;
        return acc;
    }, {});
}

function prepareData(updatedData) {
    const finalData = updatedData.reduce((acc, item) => {
        let group = acc.find((g) => g._id === item.email_table_id);
        if (group) {
            group.children.push(item);
        } else {
            acc.push({
                _id: item.email_table_id,
                treeHeader: true,
                receivedDateTime: item["receivedDateTime"],
                from: item["from"],
                type: item["type"],
                subject: item["subject"],

                children: [item],
            });
        }
        return acc;
    }, []);

    return finalData;
}

function getFileCount(bills) {
    return [...new Set(bills.map((i) => i["file_table_id"]))].length;
}