import React, { useState, useEffect } from "react";
import { Box, Stack, Toolbar, Tooltip, Typography, Backdrop, CircularProgress, IconButton } from "@mui/material";
import axios from 'axios';
import { DateRangePicker } from 'rsuite'
import 'rsuite/dist/rsuite.min.css';
import moment from "moment";
import { startOfDay, endOfDay, subDays } from 'date-fns';


import { styled } from '@mui/material/styles';
import Button from '@mui/material/Button';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import { Delete, FileUpload } from "@mui/icons-material";

import { EmailComponent } from "../Pages/EmailComponent/EmailComponent";
import Header from "../Layout/Header";
import { FetchEmailReports } from "../API/API";

import { SnackbarProvider } from "notistack";
import { enqueueSnackbar } from 'notistack'



export const statusMap = {
    0: { name: "Extraction in progress", color: "#FCC625" },
    1: { name: "Success", color: "#00bfbf" },
    "-1": { name: "Extraction failed", color: "#808000fe" },
    // "-3": { name: "Invalid file", color: "#d9001bfe" },
};



const Home = () => {


    //     const VisuallyHiddenInput = styled('input')({
    //         clip: 'rect(0 0 0 0)',
    //         clipPath: 'inset(50%)',
    //         height: 1,
    //         overflow: 'hidden',
    //         position: 'absolute',
    //         bottom: 0,
    //         left: 0,
    //         whiteSpace: 'nowrap',
    //         width: 1,
    //     });

    // const today = new Date();
    // const { afterToday } = DateRangePicker;
    // const [dateRange, setDateRange] = useState([startOfDay(today), endOfDay(today)]);
    const [payRollData, setPayRoleData] = useState([])


    // const [isUploading, setIsUplaoding] = useState(false)

    // const [fileName, setFileName] = useState([]);

    // const handleFile = (event) => {
    //     console.log(event)
    //     event.preventDefault()
    //     const selectedFiles = Array.from(event.target.files);
    //     const fileNames = selectedFiles.map(file => file.name);
    //     console.log(fileNames)
    //     setFileName(fileNames);

    // }

    // const handleSubmit = async () => {
    //     // setIsUplaoding(true)
    //     enqueueSnackbar("file successfully submite", { variant: "success" });
    //     setFileName(null)
    // }

    // const removeFile = () => {
    //     setFileName(null);
    // }


    // useEffect(() => {
    //     if (dateRange && dateRange.length === 2) {
    //         fetchEmailList();
    //     }
    // }, [dateRange])


    useEffect(() => {
        fetchEmailList();

    }, [])

    const fetchEmailList = async () => {
        // let start_date = moment(dateRange[0]).format('YYYY-MM-DD HH:mm:ss')
        // let end_date = moment(dateRange[1]).format('YYYY-MM-DD HH:mm:ss')
        // let res = await FetchEmailReports(start_date, end_date)
        let res = await FetchEmailReports()
        let data = res.data
        if (data) {
            // data = JSON.parse(data)
            setPayRoleData(data)
        }
    }

    return (
        <SnackbarProvider
            anchorOrigin={{
                vertical: "top",
                horizontal: "center",
            }}
        >
            <Stack height={'100vh'} >
                <Box>
                    <Header />
                </Box>
                <Box flex={1} overflow="auto">
                    <Stack height="100%" p={0.5}>
                        {/* <Toolbar sx={{ justifyContent: "space-between" }}> */}
                        {/* <Stack direction={"row"} gap={2} padding={0.5} alignItems={"center"}>
                                <Button
                                    size='small'
                                    component="label"
                                    role={undefined}
                                    variant="contained"
                                    tabIndex={-1}
                                    startIcon={<CloudUploadIcon />}
                                    sx={{
                                        width: "150px",
                                        height: "30px",
                                        minWidth: "auto"
                                    }}
                                >
                                    Upload files
                                    <VisuallyHiddenInput
                                        type="file"
                                        onChange={handleFile}
                                    />
                                </Button>
                                <Tooltip title={fileName}>
                                    <Typography
                                        padding={1.5}
                                        variant="body2"
                                        sx={{
                                            color: 'text.secondary',
                                            overflow: 'hidden',
                                            whiteSpace: 'nowrap',
                                            textOverflow: 'ellipsis',
                                            maxWidth: '300px',
                                        }}
                                    >
                                        {fileName}
                                    </Typography>
                                </Tooltip>

                                {
                                    fileName && fileName.length > 0 ? (
                                        <>
                                            <IconButton
                                                edge="end"
                                                size="small"
                                                onClick={() => setFileName(null)}
                                            >
                                                <Delete fontSize="small" />
                                            </IconButton>

                                            <FileUpload
                                                sx={{
                                                    color: 'text.secondary',
                                                    cursor: 'pointer',
                                                    '&:hover': {
                                                        color: 'primary.main',
                                                        transform: 'scale(1.1)',
                                                        transition: 'all 0.2s ease-in-out',
                                                    },
                                                }}
                                                onClick={handleSubmit}
                                            />

                                        </>
                                    ) : null
                                }

                                <Backdrop
                                    sx={{ color: '#fff' }}
                                    open={isUploading}
                                >
                                    <CircularProgress color="inherit" />
                                </Backdrop>

                            </Stack> */}
                        {/* <DateRangePicker
                                placeholder="Date range"
                                value={dateRange}
                                onChange={(date) => setDateRange(date)}
                                shouldDisableDate={afterToday()}
                                placement="bottomEnd"
                            />
                        </Toolbar> */}

                        <EmailComponent data={payRollData} />
                    </Stack>
                </Box >
            </Stack >
        </SnackbarProvider>
    );
}

export default Home;