import {
    Stack,
    Accordion,
    AccordionSummary,
    AccordionDetails,
    Box,
    Typography,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableRow,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

function RenderTable({ title, data, hideHeading = false }) {
    // if (Object.keys(data).length === 0) return null;
    if (!data || typeof data !== "object" || Object.keys(data).length === 0) return null;

    return (
        <TableContainer sx={{ marginTop: 1 }}>
            <Table size="small">
                <TableBody
                // sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                >
                    {!hideHeading && (
                        <TableRow>
                            <TableCell colSpan={2} sx={{ backgroundColor: "#E0E0E0", padding: "4px", height: 15, borderRadius: 1, }}>
                                <Typography
                                    sx={{ lineHeight: 1 }}
                                    fontWeight="bold"
                                    variant="subtitle1"
                                >
                                    {formatString(title)}
                                </Typography>
                            </TableCell>
                        </TableRow>
                    )}
                    {Object.entries(data).map(([key, value]) => (
                        <TableRow key={key}>

                            <TableCell sx={{ padding: "4px", height: 10 }}>
                                {formatString(key)}
                            </TableCell>

                            <TableCell sx={{ padding: "4px", height: 10 }}>
                                {typeof value === "object" && value !== null ? (
                                    <Box sx={{
                                        border: 1,
                                        borderColor: "divider",
                                        borderRadius: 2,
                                        padding: 1,
                                        marginY: 0.5
                                    }}>
                                        <RenderTable
                                            title={key}
                                            data={value}
                                            hideHeading={true}
                                        />
                                    </Box>
                                ) : typeof value === "number" ? (
                                    value.toFixed(2)
                                ) : value === null || value === undefined ? (
                                    "-"
                                ) : (
                                    value
                                )}
                            </TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    );
}

function formatString(str) {
    return str.split("_").map((word) => word[0].toUpperCase() + word.slice(1)).join(" ");
}

function RenderAccordion({ title, data }) {
    return (
        <Accordion sx={{ border: 1, borderColor: "divider", borderRadius: "4px" }}>
            <AccordionSummary expandIcon={<ExpandMoreIcon />} sx={{ padding: 1 }}>
                <Typography variant="subtitle2" fontWeight="bold">
                    {title}
                </Typography>
            </AccordionSummary>
            <AccordionDetails>
                <Box border={1} borderColor={"divider"} borderRadius={2} p={0.5}>
                    {Object.entries(data).map(([key, val]) =>
                        typeof val === "object" ? (
                            <RenderTable key={key} title={formatString(key)} data={val} />
                        ) :
                            null
                        // (
                        //     <TableContainer key={key} sx={{ marginTop: 1 }}>
                        //         <Table size="small">
                        //             <TableBody>
                        //                 <TableRow>
                        //                     <TableCell sx={{ padding: "4px", height: 5 }}>
                        //                         {formatString(key)}
                        //                     </TableCell>
                        //                     <TableCell sx={{ padding: "4px", height: 5 }}>
                        //                         {val}
                        //                     </TableCell>
                        //                 </TableRow>
                        //             </TableBody>
                        //         </Table>
                        //     </TableContainer>
                        // )

                    )}
                </Box>
            </AccordionDetails>
        </Accordion>
    );
}

const FormPanel = ({ data }) => {
    
    const companyDetails = data.company_info || {};
    const employees = data.employees || [];

    return (
        <Stack height={"100%"} overflow={"auto"} border={1} borderColor={"divider"}>
            <Box sx={{ display: "flex", flexDirection: "column", gap: 0.5 }} padding={0.5}>
                <Box border={1} borderColor={"divider"} mb={2} p={0.5} >
                    <RenderTable title="Company Details" data={companyDetails} />
                </Box>
                {employees.map((value, index) => (
                    <RenderAccordion
                        key={index}
                        title={value.employee_details.name || value.employee_details.employee_name}
                        data={value}
                    />

                ))}
            </Box>
        </Stack>
    );
};

export default FormPanel;
