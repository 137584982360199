import Home from "./Home/Home";
import { ThemeProvider } from "@emotion/react";
import theme from './Theme/Theme'

const App = () => {
  return (
    <ThemeProvider theme={theme}>
        <Home />
    </ThemeProvider>
  );
}

export default App;
